<template>
  <div class="container">
    <validation-observer ref="observer">
      <md-field title="个人信息" class="margin">
        <v-input title="姓名" placeholder="请填写姓名" v-model="formData.name" clearable rules="required" required isTitleLatent></v-input>
        <v-input
          title="电话"
          placeholder="请填写电话"
          v-model="formData.phone"
          clearable
          rules="required|phone"
          required
          isTitleLatent
        ></v-input>
        <v-input title="所属公司" placeholder="请填写所属公司" v-model="formData.company" clearable isTitleLatent></v-input>
        <v-input
          title="所在省份"
          placeholder="请填写所在省份"
          v-model="formData.province"
          rules="required"
          required
          clearable
          isTitleLatent
        ></v-input>
      </md-field>
    </validation-observer>
    <div class="margin">
      <md-button type="primary" @click="handleSubmit">提交</md-button>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import VInput from '@/components/VInput/Index.vue'
import { userInfo } from '@/api/user'

export default {
  components: { ValidationObserver, VInput },
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        company: '',
        province: '',
      },
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.observer.validate().then(result => {
        if (!result) {
          return
        }
        const { id } = this.$store.getters['user/info']
        userInfo(id, this.formData).then(() => this.$router.push({ name: 'home' }))
      })
    },
  },
}
</script>
<style lang="stylus" scoped></style>
